import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="About Dr. Kaloti – Best Cosmetic Dentist Toronto | The Smile Stylist"
     description="The Smile Stylist, Dr. Kaloti is a smile makeover and porcelain veneer specialist who uses cosmetic procedures to enhance the health, beauty and rejuvenation of smiles." 
     keywords="Best cosmetic dentist toronto" />
    <main className="site-content" role="main">
    <section id="about_mti" className="about hidden-xs">
      <img src={require("../images/desktop/meet_dr_kaloti.jpg")} alt="Meet Dr Kaloti" />
    </section>

    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <h1 className="lead-black-bigger">Dr. Hisham Kaloti is a dental artist who relies on conservative procedures to enhance the Health, Function, Beauty and Rejuvenation of smiles.</h1>
            <p>His expertise in blending the science of dentistry with his artistic ability allows him to create natural appearing smiles on hundreds of people from all over the world so they can look and feel their best. A graduate of the University of Michigan School of Dentistry. He is also a Fellow and member of the International Congress Of Oral Implantologist, and a member of the American Academy of Cosmetic Dentistry. Dr. Kaloti was the founder and served as the Chief Operating Officer of Dental Reflections, a dental chain with locations throughout the Midwest.</p>

            <h2>Accomplished Author and Speaker</h2>
            <p>Dr. Kaloti is an accomplished best selling author and international lecturer on the topic of cosmetic dentistry. He is also the recipient of the Donald A. Kerr Oral Pathology Award, the 2007 and 2010 Kids Connection Award. He has also served as the Regional Coordinator of the New Dentist Alliance, training dentists about <a href="/advanced-technology">the latest dental technologies</a> and procedures. His research related to dental implants was published in Journal of National Institute Of Dental Research. Dr Kaloti has been the dentist of choice for over 10,000 patients over 15 years in 4 states and two provinces! His passion for creating for one-of-a-kind smiles has been featured on NBC, CBS, ABC and FOX affiliates.</p>

            <h2>A Giver and Philantropist</h2>
            <p>Dr. Kaloti dedicates time every year to conduct a Dental Makeover Contest where the winner receives upto $100,000 worth of cosmetic dentistry pro bono. He also dedicates time to providing dental care to impoverished communities in Haiti, Mexico, and Somalia through the Kaloti Foundation, a non-profit organization dedicated to improving children’s oral health world-wide.</p>

            <h2>Awards & Recognition</h2>
            <ul>
              <li>2007 Kids Connection Award</li>
              <li>2010 Kids Connection Award</li>
              <li>2014 Best Dentist Award – Cambridge Times</li>
              <li>2016 Top Doc Award – Realself.com</li>
            </ul>
            <h2>Memberships & Affiliations</h2>
            <ul>
              <li>Member of the AACD (American Academy of Cosmetic Dentistry)</li>
              <li>Member of the ODA (Ontario Dental Association)</li>
              <li>Member of the RCDSO (Royal College of Dental Surgeons of Ontario)</li>
            </ul>
          </div>
          <div className="col-sm-4 wow animated zoomIn text-center" data-wow-delay="0.6s">
            <img className="kaloti_magazine" alt="Dr. Kaloti" src={require("../images/desktop/mgr.png") }/>
            <h3>The Smile Stylist Magazine</h3>
            <p>Learn more about Dr. Kaloti’s mission to Haiti, see how to achieve a celebrity smile, take our very own smile design quiz and more!</p>
            <p><a className="btn btn-black" target="_blank" href="/Smilestylist Magazine - The Smile Stylist.pdf">Download</a></p>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage